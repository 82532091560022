import React from "react";

import { Link } from "react-router-dom";

interface IProps {
  icon: string;
  text: string;
  to: string;
  target?: React.HTMLAttributeAnchorTarget;
}

const SocialLink: React.FC<IProps> = ({ icon, text, to, target }) => {
  return (
    <Link to={to} target={target}>
      <i className={`bi bi-${icon} me-2`} />
      {text}
    </Link>
  );
};

export default SocialLink;
