import React from "react";

import avatar from "../../img/default.jpg";
import { useTranslation } from "react-i18next";
import SocialLink from "./socialLink";

const Sidebar: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="profile-sidebar">
      <div className="desktop-sticky-top">
        <div className="profile-img">
          <img src={avatar} alt={i18n.t("avatar.alt")} />
        </div>
        <h4 className="mb-3">{t("name")}</h4>
        <div className="mb-2 text-inverse text-opacity-50 fw-bold mt-n2">
          <SocialLink
            icon="github"
            text={i18n.t("github")}
            to={process.env.REACT_APP_GITHUB_PATH || "#"}
          />
        </div>
        <div className="mb-2 text-inverse text-opacity-50 fw-bold mt-n2">
          <SocialLink
            icon="linkedin"
            text={i18n.t("linkedin")}
            to={process.env.REACT_APP_LINKEDIN_PATH || "#"}
            target="_blank"
          />
        </div>
        <p>{t("description")}</p>
        <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">
          <SocialLink
            icon="geo-alt-fill"
            text={i18n.t("position")}
            to={process.env.REACT_APP_GOOGLE_MAP_PATH || "#"}
            target="_blank"
          />
        </div>
        <div className="mb-3 text-inverse text-opacity-50 fw-bold mt-n2">
          <SocialLink
            icon="envelope-fill"
            text={i18n.t("mail")}
            to={`mailto:${i18n.t("mail")}`}
            target="_blank"
          />
        </div>
        <hr className="mt-4 mb-4" />
        <div className="fw-bold mb-3 fs-16px">{t("diploma.title")}</div>
        <ul>
          {[...Array(4)].map((x, k) => (
            <li key={k}>
              <small>{t(`diploma.diploma${k + 1}`)}</small>
            </li>
          ))}
        </ul>
        <hr className="mt-4 mb-4" />
        <div className="fw-bold mb-3 fs-16px">{t("languages.title")}</div>
        <ul>
          {[...Array(5)].map((x, k) => (
            <li key={k}>
              <small>{t(`languages.language${k + 1}`)}</small>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
