import React from "react";

import ProfileTab from "./profileTab";
import { Col, Row } from "react-bootstrap";

import cityscoot from "../../img/cityscoot.png";
import aphp from "../../img/aphp.jpg";
import ExpCard from "./expCard";

const Content: React.FC = () => {
  return (
    <div className="profile-content">
      <ProfileTab />
      <div className="profile-content-container">
        <Row className="gx-4">
          <Col lg={12}>
            <div className="tab-content p-0">
              <div className="tab-pane fade show active">
                <ExpCard avatar={cityscoot} i18nKey="cityscoot" projects={7} />
                <ExpCard avatar={aphp} i18nKey="aphp" projects={3} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Content;
