const fr = {
  translation: {
    name: "Alexis Courapied",
    github: process.env.REACT_APP_GITHUB || "Github",
    linkedin: process.env.REACT_APP_LINKEDIN || "Linkedin",
    position: process.env.REACT_APP_ADDRESS || "Paris",
    mail: process.env.REACT_APP_MAIL || "",
    languages: {
      title: "Langages connus",
      language1: "JavaScript/TypeScript",
      language2: "Golang (autodidacte)",
      language3: "Rust (autodidacte)",
      language4: ".NET",
      language5: "PHP",
    },
    profileTab: {
      react: "ReactJS",
      nodejs: "NodeJS",
      angular: "AngularJS",
      golang: "Golang",
    },
    diploma: {
      title: "Mes diplômes",
      diploma1: "BAC+2 : BTS Services Informatiques aux Organisations (SIO)",
      diploma2: "BAC+3 : Concepteur développeur d’applications",
      diploma3: "BAC+5 : Architecte technique en informatique",
      diploma4: "Permis de conduire",
    },
    tooltipLanguage: "Changer de langue",
    project: "Projets réalisés",
    cityscoot: {
      name: "Cityscoot",
      position: "à Paris",
      old: "Recruté en octobre 2017 - 7 ans d'expérience",
      description:
        "Engagé en tant que développeur full stack JavaScript. J'ai terminé mon aventure chez Cityscoot en tant que Lead Développeur Full Stack",
      projects: {
        project1: {
          name: "Orchestra",
          description:
            "Refonte d'un projet back-office monolithique pour la gestion des scooters de Cityscoot. Collaboration étroite avec les équipes terrain et technique pour répondre aux divers besoins métiers",
          languages: "ASP .NET",
        },
        project2: {
          name: "Inscription",
          description:
            "Conception et réalisation du parcours d'inscription utilisateur. Projet réalisé en collaboration front/back/produit",
          languages: "NodeJS, MySQL, Redis",
        },
        project3: {
          name: "Documents",
          description:
            "Refonte de l'architecture d'envoi des documents officiels des utilisateurs. Projet basé sur une architecture existante en coordination avec l'équipe technique 'admin'.",
          languages: "NodeJS, MySQL, RabbitMQ",
        },
        project4: {
          name: "Transactionnel",
          description:
            "Conception et réalisation d'applications assurant la gestion des envois de SMS et d'emails. Projet réalisé de manière autonome, en coordination avec les propriétaires des différents projets existant pour faciliter la migration de leur métier lors de la conception.",
          languages: "NodeJS, MySQL, Redis, RabbitMQ",
        },
        project5: {
          name: "Mobile",
          description:
            "Participation à la refonte de la nouvelle application mobile de Cityscoot. Projet réalisé en équipe.",
          languages: "React Native",
        },
        project6: {
          name: "LiveFleet",
          description:
            "Refonte d'une application front permettant de visualiser en semi-temps réel la position des scooters des différents services de Cityscoot. Projet réalisé en autonomie.",
          languages: "ReactJS, NodeJS, MapBoxGL",
        },
        project7: {
          name: "AdminV3",
          description:
            "Conception et réalisation d'une application front pour la gestion des comptes employés et des paramètres de l'application/service. Projet réalisé en équipe pour répondre aux besoins.",
          languages: "AngularJS, NodeJS",
        },
      },
    },
    aphp: {
      name: "APHP",
      position: "Assistance Publique - Hôpitaux de Paris",
      old: "Recruté en septembre 2012 - 5 ans d'expérience",
      description: "Engagé en tant que développeur web en alternance au siège.",
      projects: {
        project1: {
          name: "Gestime",
          description:
            "Création d'un nouveau site intranet pour la gestion des congés des agents.",
          languages: "PHP",
        },
        project2: {
          name: "Transactionnels",
          description:
            "Développement d'un logiciel pour recevoir et traiter des données MySQL depuis un serveur FTP. Réception, validation et insertion automatisée.",
          languages: "JS/.NET",
        },
        project3: {
          name: "API REST",
          description:
            "Développement d'une API REST permettant aux services informatiques des hôpitaux de recevoir des données brutes du siège, remplaçant les demandes par email",
          languages: "NodeJS",
        },
      },
    },
    description:
      "Développeur web senior full stack JavaScript avec une expertise confirmée en ReactJS, AngularJS, NodeJS et Golang. Titulaire d'un Master 2 en Architecture et Développement Web",
    avatar: {
      alt: "Ma photo de profil",
    },
  },
};

export default fr;
