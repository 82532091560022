import React from "react";
import "./App.css";

import {
  createBrowserRouter,
  RouterProvider,
  redirect,
} from "react-router-dom";
import Home from "./features/home";
import Default from "./container/default";

const router = createBrowserRouter([
  {
    path: "",
    element: <Default />,
    children: [
      {
        path: "",
        element: <Home />,
      },
    ],
  },
  {
    path: "*",
    action: () => redirect("/"),
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
