import React from "react";

import Content from "./content";
import { Outlet } from "react-router-dom";

const Default: React.FC = () => {
  return (
    <Content>
      <Outlet />
    </Content>
  );
};

export default Default;
