const en = {
  translation: {
    name: process.env.REACT_APP_NAME || "Nyura95",
    github: process.env.REACT_APP_GITHUB || "Github",
    linkedin: process.env.REACT_APP_LINKEDIN || "Linkedin",
    position: process.env.REACT_APP_ADDRESS || "Paris",
    mail: process.env.REACT_APP_MAIL || "@gmail.com",
    languages: {
      title: "Known Languages",
      language1: "JavaScript/TypeScript",
      language2: "Golang",
      language3: "Rust",
      language4: ".NET",
      language5: "PHP",
    },
    profileTab: {
      react: "ReactJS",
      nodejs: "NodeJS",
      angular: "AngularJS",
      golang: "Golang",
    },
    diploma: {
      title: "My Degrees",
      diploma1: "BAC+2: BTS Services Informatiques aux Organisations (SIO)",
      diploma2: "BAC+3: Application Developer Designer",
      diploma3: "BAC+5: Technical Architect in IT",
      diploma4: "Driver's License",
    },
    tooltipLanguage: "Change language",
    project: "Completed Projects",
    cityscoot: {
      name: "Cityscoot",
      position: "in Paris",
      old: "Hired in October 2017 - 7 years of experience",
      description:
        "Hired as a full stack JavaScript developer. I finished my career at Cityscoot as a Lead Full Stack Developer",
      projects: {
        project1: {
          name: "Orchestra",
          description:
            "Overhaul of a monolithic back-office project for managing Cityscoot scooters. Close collaboration with the field and technical teams to meet various business needs",
          languages: "ASP .NET",
        },
        project2: {
          name: "Registration",
          description:
            "Design and implementation of the user registration process. Project carried out in collaboration with the front/back/product teams",
          languages: "NodeJS, MySQL, Redis",
        },
        project3: {
          name: "Documents",
          description:
            "Redesign of the architecture for sending official user documents. Project based on a legacy architecture in coordination with the 'admin' technical team",
          languages: "NodeJS, MySQL, RabbitMQ",
        },
        project4: {
          name: "Transactional",
          description:
            "Design and implementation of applications that manage SMS and email transactions. Project carried out independently, coordinating and adapting with the owners of the different projects to facilitate migration during the design",
          languages: "NodeJS, MySQL, Redis, RabbitMQ",
        },
        project5: {
          name: "Mobile",
          description:
            "Participation in the redesign of the new Cityscoot mobile application. Project carried out in a team",
          languages: "React Native",
        },
        project6: {
          name: "LiveFleet",
          description:
            "Overhaul of a front-end application to visualize in semi-real-time the position of scooters from different Cityscoot services. Project carried out independently",
          languages: "ReactJS, NodeJS, MapBoxGL",
        },
        project7: {
          name: "AdminV3",
          description:
            "Design and implementation of a front-end application for managing employee accounts and application/service settings. Project carried out in a team to meet needs",
          languages: "AngularJS, NodeJS",
        },
      },
    },
    aphp: {
      name: "APHP",
      position: "Assistance Publique - Hôpitaux de Paris",
      old: "Hired in September 2012 - 5 years of experience",
      description:
        "Hired as a web developer in a work-study program at the headquarters",
      projects: {
        project1: {
          name: "Gestime",
          description:
            "Creation of a new intranet site for managing employee leave",
          languages: "PHP",
        },
        project2: {
          name: "Transactional",
          description:
            "Development of a small software to receive and process MySQL data from an FTP server. Automated reception, validation, and insertion",
          languages: "JS/.NET",
        },
        project3: {
          name: "REST API",
          description:
            "Development of a REST API allowing hospital IT services to receive raw data from the headquarters, replacing email requests",
          languages: "NodeJS",
        },
      },
    },
    description:
      "Senior full stack JavaScript web developer with confirmed expertise in ReactJS, AngularJS, NodeJS, and Golang. Holder of a Master's degree in Web Architecture and Development",
    avatar: {
      alt: "Profile photo",
    },
  },
};

export default en;
