import React from "react";

import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import CustomCard from "../../components/card";
import { Link } from "react-router-dom";

interface IProps {
  i18nKey: string;
  avatar: string;
  projects: number;
}

const ExpCard: React.FC<IProps> = ({ i18nKey, avatar, projects }) => {
  const { t } = useTranslation();

  return (
    <CustomCard className="mb-3">
      <Card.Body>
        <div className="d-flex align-items-center mb-3">
          <Link to="#">
            <img src={avatar} alt="" width="50" className="rounded-circle" />
          </Link>
          <div className="flex-fill ps-2">
            <div className="fw-bold">
              <Link to={"#"} className="text-decoration-none">
                {t(`${i18nKey}.name`)}
              </Link>
              {` ${t(`${i18nKey}.position`)}`}
            </div>
            <div className="small text-inverse text-opacity-50">
              {t(`${i18nKey}.old`)}
            </div>
          </div>
        </div>
        <p>{t(`${i18nKey}.description`)}</p>
        <hr className="mb-1" />
        <p>{t("project")}</p>
        <ul>
          {[...Array(projects)].map((x, k) => (
            <li key={k} className="mb-1">
              <dt>{t(`${i18nKey}.projects.project${k + 1}.name`)}</dt>
              {`${t(`${i18nKey}.projects.project${k + 1}.description`)} (${t(
                `${i18nKey}.projects.project${k + 1}.languages`
              )})`}
            </li>
          ))}
        </ul>
      </Card.Body>
    </CustomCard>
  );
};

export default ExpCard;
