import React from "react";
import { Container } from "react-bootstrap";

interface IProps {
  children?: React.ReactNode;
}

const Content: React.FC<IProps> = ({ children }) => {
  return <Container className="pb-3">{children}</Container>;
};

export default Content;
