import React, { useCallback, useMemo } from "react";
import Stars from "../../components/stars";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { languages } from "../../i18n";

interface IProps {
  mobile?: boolean;
}

const ProfileTab: React.FC<IProps> = ({ mobile }) => {
  const { i18n } = useTranslation();

  const items = useMemo(() => {
    return [
      { title: i18n.t("profileTab.react"), star: 4 },
      { title: i18n.t("profileTab.nodejs"), star: 4 },
      { title: i18n.t("profileTab.angular"), star: 3 },
      { title: i18n.t("profileTab.golang"), star: 4 },
    ];
  }, [i18n]);

  const switchLanguages = useCallback(() => {
    const currentIndex = languages.indexOf(i18n.language);
    if (currentIndex === -1) return;
    const nextIndex = (currentIndex + 1) % languages.length;
    i18n.changeLanguage(languages[nextIndex]);
  }, [i18n]);

  return (
    <ul
      className={`profile-tab nav nav-tabs nav-tabs-v2${
        mobile ? " mobile" : ""
      }`}
      role="tablist"
    >
      <Link to="#" onClick={switchLanguages}>
        <i
          className="fa fa-flag position-absolute end-0 mt-2 me-2 pointer"
          data-toggle="tooltip"
          data-placement="top"
          title={i18n.t("tooltipLanguage")}
        />
      </Link>
      {items.map((x, k) => (
        <li key={k} className="nav-item" role="presentation">
          <div className="nav-link" data-bs-toggle="tab" role="tab">
            <div className="nav-field">{x.title}</div>
            <div className="nav-value">
              <Stars star={x.star} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ProfileTab;
