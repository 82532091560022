import React from "react";
import { Card, CardProps } from "react-bootstrap";

interface IProps extends CardProps {
  children?: React.ReactNode;
}

const CustomCard: React.FC<IProps> = ({ children, ...props }) => {
  return (
    <Card {...props}>
      {children}
      <div className="card-arrow">
        <div className="card-arrow-top-left"></div>
        <div className="card-arrow-top-right"></div>
        <div className="card-arrow-bottom-left"></div>
        <div className="card-arrow-bottom-right"></div>
      </div>
    </Card>
  );
};

export default CustomCard;
