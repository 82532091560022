import React from "react";

interface IProps {
  star: number;
  className?: string;
}

const Stars: React.FC<IProps> = ({ star, className }) => {
  return (
    <>
      {[...Array(star)].map((x, k) => (
        <i
          key={k}
          style={{ fontSize: "0.8125rem" }}
          className={`bi bi-star-fill${className ? ` ${className}` : ""}`}
        />
      ))}
    </>
  );
};

export default Stars;
