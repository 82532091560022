import React from "react";
import { Card } from "react-bootstrap";
import CustomCard from "../../components/card";

import Sidebar from "./sidebar";
import Content from "./content";
import ProfileTab from "./profileTab";

const Home: React.FC = () => {
  return (
    <CustomCard>
      <Card.Body className="p-0">
        <div className="profile">
          <div className="profile-container">
            <ProfileTab mobile />
            <Sidebar />
            <Content />
          </div>
        </div>
      </Card.Body>
    </CustomCard>
  );
};

export default Home;
